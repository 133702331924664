<template>
  <div class="main-container">
    <div class="banner">
      <h1>矩尺网络&nbsp;&nbsp;|&nbsp;&nbsp;应用场景</h1>
      <div class="title-devide-line"></div>
    </div>
    <div class="situation-tabs-bar">
      <span class="situation-tab" :class="{'is-active': activeTab === '应用场景'}" @click="activeTab = '应用场景'">应用场景</span>
      <span class="situation-tab" :class="{'is-active': activeTab === '跨云集中管理'}" @click="activeTab = '跨云集中管理'">跨云集中管理</span>
      <span class="situation-tab" :class="{'is-active': activeTab === '产品目前适配情况'}" @click="activeTab = '产品目前适配情况'">产品目前适配情况</span>
    </div>
    <div class="situation-tabs-content scene" v-show="activeTab === '应用场景'">
      <aside>
        <ul>
          <li :class="{'is-active': activeAside === '基础负载均衡业务-HTTP/TCP'}" @click="activeAside='基础负载均衡业务-HTTP/TCP'"><svg-icon class="scene-icon" icon-class="scene" />基础负载均衡业务-HTTP/TCP</li>
          <li :class="{'is-active': activeAside === '基础负载均衡业务-SSL卸载'}" @click="activeAside='基础负载均衡业务-SSL卸载'"><svg-icon class="scene-icon" icon-class="scene" />基础负载均衡业务-SSL卸载</li>
          <li :class="{'is-active': activeAside === '服务器健康检查'}" @click="activeAside='服务器健康检查'"><svg-icon class="scene-icon" icon-class="scene" />服务器健康检查</li>
          <li :class="{'is-active': activeAside === '会话保持'}" @click="activeAside='会话保持'"><svg-icon class="scene-icon" icon-class="scene" />会话保持</li>
          <li :class="{'is-active': activeAside === '服务器温暖上线'}" @click="activeAside='服务器温暖上线'"><svg-icon class="scene-icon" icon-class="scene" />服务器温暖上线</li>
          <li :class="{'is-active': activeAside === '连接复用场景'}" @click="activeAside='连接复用场景'"><svg-icon class="scene-icon" icon-class="scene" />连接复用场景</li>
          <li :class="{'is-active': activeAside === '业务灰度发布管理'}" @click="activeAside='业务灰度发布管理'"><svg-icon class="scene-icon" icon-class="scene" />业务灰度发布管理</li>
          <li :class="{'is-active': activeAside === '按需定制分发策略'}" @click="activeAside='按需定制分发策略'"><svg-icon class="scene-icon" icon-class="scene" />按需定制分发策略</li>
        </ul>
      </aside>
      <main>
        <div class="situation-tabs-content-main-wrap">
          <div class="situation-tabs-content-main-content" v-show="activeAside==='基础负载均衡业务-HTTP/TCP'" >
            <div class="bg-text">场景1</div>
            <h2>基础负载均衡业务-HTTP/TCP</h2>
            <ul>
              <li>
                <span class="label icon-circle">SSL：</span>
                <span>业务访问环境无SSL加密\卸载需求；</span>
              </li>
              <li>
                <span class="label icon-circle">负载均衡算法：</span>
                <span>根据业务需要，将不同业务真实服务器池负载均衡算法设置为轮询、带权重的轮询、动态连接数、一致性hash算法等；</span>
              </li>
              <li>
                <span class="label icon-circle">服务器健康检查：</span>
                <span>根据业务需要，将不同业务真实服务器池健康检查策略设置为icmp、tcp、http；</span>
              </li>
              <li>
                <span class="label icon-circle">协议类型：</span>
                <span>根据业务需要，设置虚拟服务协议类型为TCP、HTTP；</span>
              </li>
              <li>
                <span class="label icon-circle">目的端口转换：</span>
                <span>无；</span>
              </li>
              <li>
                <span class="label icon-circle">源地址转换：</span>
                <span>源地址转换为虚拟服务IP；</span>
              </li>
              <li>
                <span class="label icon-circle">溯源：</span>
                <span>无；</span>
              </li>
              <li>
                <span class="label icon-circle">会话保持：</span>
                <span>无；</span>
              </li>
            </ul>
            <img src="@/assets/imgs/situation-HttpTcp.png" width="602" height="330" style="transform: translate(190px, 50px);" alt="">
          </div>
          <div class="situation-tabs-content-main-content" v-show="activeAside==='基础负载均衡业务-SSL卸载'" >
            <div class="bg-text">场景2</div>
            <h2>基础负载均衡业务-SSL卸载</h2>
            <ul>
              <li class="icon-squire">部署在服务器前端，实现明文数据与加密数据的转换，为外网用户构建安全的传输通道；</li>
              <li class="icon-squire">服务端应用无需做任何调整，减少开发人员工作量；</li>
              <li class="icon-squire">只需购买1对SSL卸载设备，服务器性能不下降，大幅节约硬件投资成本。</li>
            </ul>
            <img src="@/assets/imgs/situation-SSL-unload.png" width="600" height="371" style="transform: translate(20px, 50px);" alt="" loading="lazy">
          </div>
          <div class="situation-tabs-content-main-content" v-show="activeAside==='服务器健康检查'" >
            <div class="bg-text">场景3</div>
            <h2>服务器健康检查</h2>
            <ul>
              <li>
                <h4 class="icon-squire">健康检查</h4>
                <p>健康检查用来判断后台服务器存活状态。</p>
                <p class="icon-circle">特定的应用程序，一个续期的回复，在一定时间内。</p>
              </li>
              <li>
                <h4 class="icon-squire">失败后的处理动作</h4>
                <p class="icon-circle break-line">标记节点为异常，从现有资源池中剔除→继续进行探测→没有新连接到这个成员member，但现有连接被维持→达到了超时时间前有一个成功的检查结果新连接发送到节点。</p>
              </li>
              <li>
                <h4 class="icon-squire">复合健康监测</h4>
                <p>可以使用复合健康监测, 所以可使用多重健康检查。</p>
                <p class="icon-circle">可以使用全部或者部分的健康检查结果来标记成员的状态。</p>
              </li>
              <li>
                <h4 class="icon-squire">复合健康监测</h4>
                <p class="icon-circle">基于硬件运行状况的主动检查：通过PING、SNMP等方式监控服务器的运行状况；</p>
                <p class="icon-circle">基于应用类型的主动检查：TCP、UDP、HTTP、DNS等都可以通过相应的检测机制监控应用的运行状况；</p>
                <p class="icon-circle">自定义内容检查机制：是通过预设自定义脚本，来判断服务器应用是否运行正常;</p>
                <p class="icon-circle">基于观测方式的被动检查：根据观测到的服务器连接数，上下行数据报文。</p>
              </li>
            </ul>
            <img src="@/assets/imgs/situation-health-check.png" width="750" height="325" style="transform: translate(20px, 0px);" alt="" loading="lazy">
          </div>
          <div class="situation-tabs-content-main-content" v-show="activeAside==='会话保持'" >
            <div class="bg-text">场景4</div>
            <h2>会话保持</h2>
            <h4>提供全面的会话保持算法</h4>
            <ul>
              <li class="icon-squire">
                <span class="break-line">会话保持用以识别客户与服务器之间交互过程的关联性，在作负载均衡的同时，还保证一系列相关联的访问请求会保持分配到一台服务器上，以确保应用不会出错。</span>
              </li>
              <li class="icon-squire">
                <span class="break-line">支持的会话保持方式：基于源IP保持、Cookie（插入、被动、改写）、HTTP-Header、SSL Session ID。</span>
              </li>
              <li class="icon-squire">
                <span class="break-line">与应用系统无缝结合，保障业务连续性。</span>
              </li>
            </ul>
            <img src="@/assets/imgs/situation-session-persistence.png" width="600" height="304" style="transform: translateX(20px);" alt="" loading="lazy">
          </div>
          <div class="situation-tabs-content-main-content" v-show="activeAside==='服务器温暖上线'" >
            <div class="bg-text">场景5</div>
            <h2>服务器温暖上线</h2>
            <h4>新增服务器温暖上线</h4>
            <ul>
              <li class="icon-squire">
                <span class="break-line">将新购置或维护后的服务器添加到服务器组时，设备可以通过温暖上线方式，避免新服务器由于激增流量的冲击而导致系统故障，实现服务器的平滑进入。</span>
              </li>
              <li class="icon-squire">
                <span class="break-line">当新服务器上线后，在其恢复时间内，设备不会向该服务器发送请求；而在随后的温暖时间以内，设备则会逐渐地增加分配到该服务器地请求，使新服务器地压力缓慢增加到稳定状态，从而保证服务器在启动期间以及应用程序初始化时都能提供不间断服务。</span>
              </li>
            </ul>
            <img src="@/assets/imgs/situation-worm-onload.png" width="600" height="350" style="transform: translate(150px, 50px);" alt="" loading="lazy">
          </div>
          <div class="situation-tabs-content-main-content" v-show="activeAside==='连接复用场景'" >
            <div class="bg-text">场景6</div>
            <h2>连接复用场景</h2>
            <h4>提供基于连接的TCP复用机制</h4>
            <ul>
              <li class="icon-squire">
                <span class="break-line">通过将众多客户端连接请求捆绑后，复用相对较少的服务器TCP连接。</span>
              </li>
              <li class="icon-squire">
                <span class="break-line">不需要改变任何网络构造，也不需要增加组织的硬件投资成本。</span>
              </li>
              <li class="icon-squire">
                <span class="break-line">适用与大量的连接建立/拆卸的应用，减少服务器的工作负荷。</span>
              </li>
            </ul>
            <img src="@/assets/imgs/situation-connection-reuse.png" width="500" height="252" style="transform: translate(0px, 0px);" alt="" loading="lazy">
          </div>
          <div class="situation-tabs-content-main-content" v-show="activeAside==='业务灰度发布管理'" >
            <div class="bg-text">场景7</div>
            <h2>业务灰度发布管理</h2>
            <ul>
              <li>
                <h4 class="icon-squire">灰度流量管理</h4>
                <p class="break-line">可同时运行两个或多个版本（例如网站、APP应用），部分用户访问老版本，部分用户访问新版本，用以测试新版本问题、客户喜好度等。同时不需要中断业务来做调整。</p>
              </li>
              <li>
                <h4 class="icon-squire">根据用户IP地址区分</h4>
              </li>
              <li>
                <h4 class="icon-squire">根据版本权重区分</h4>
              </li>
              <li>
                <h4 class="icon-squire">根据cookie设置区分</h4>
              </li>
            </ul>
            <img src="@/assets/imgs/situation-gated-launch.png" width="600" height="415" style="transform: translate(200px, 0px);" alt="" loading="lazy">
          </div>
          <div class="situation-tabs-content-main-content" v-show="activeAside==='按需定制分发策略'" >
            <div class="bg-text">场景8</div>
            <h2>按需定制分发策略</h2>
            <ul>
              <li class="icon-squire break-line">在同一个虚拟服务内，可以创建多条具备不同优先级的分发规则，每条分发规则可以指定请求路由到不同的服务器池，亦或是返回重定向响应等。</li>
              <li class="icon-squire break-line">这样命中虚拟服务的请求就会根据分发规则转发不同的服务器池。其中，每条分发规则包含多条分发策略，而分发策略是基于源地址、URL、COOKIE等请求信息生效的。</li>
            </ul>
            <img src="@/assets/imgs/situation-dispatch-strategy.png" width="800" height="438" style="transform: translate(10px, 50px);" alt="" loading="lazy">
          </div>
        </div>
      </main>
    </div>
    <div class="situation-tabs-content" v-show="activeTab === '跨云集中管理'">
      <TitlePoint title="实现跨云的集中管理" />
      <img src="@/assets/imgs/centralized-management.png" width="900" style="margin: 0 auto;" alt="" loading="lazy">
    </div>
    <div class="situation-tabs-content" v-show="activeTab === '产品目前适配情况'">
      <TitlePoint title="产品目前适配情况" />
      <img src="@/assets/imgs/certificate.jpg" width="850" height="590" style="margin: 0 auto;" alt="" loading="lazy">
    </div>
  </div>
</template>

<script>
import TitlePoint from '@/components/TitlePoint.vue'
export default {
  components: {
    TitlePoint
  },
  data() {
    return {
      activeTab: '应用场景',
      activeAside: '基础负载均衡业务-HTTP/TCP'
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
.banner{
  height: 185px;
  background-image: url('~@/assets/imgs/situation-top-bg.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  h1 {
    margin-bottom: 10px;
  }
  .title-devide-line{
    width: 155px!important;
  }
}
.situation-tabs-bar{
  display: flex;
  align-items: center;
  padding: 20px 0;
  .situation-tab{
    color: #555555;
    display: inline-block;
    font-size: 15px;
    margin-left: 100px;
    padding: 8px 30px;
    border-radius: 18px;
    box-sizing: border-box;
    cursor: pointer;
    &.is-active {
      background-color: $primaryColor;
      color: #fff;
    }
  }
}
.situation-tabs-content{
  background-color: #fff;
  padding: 50px;
  &.scene {
    display: flex;
    aside ul li{
      width: 302px;
      text-align: left;
      box-sizing: border-box;
      color: #868686;
      height: 60px;
      line-height: 60px;
      padding: 0 30px 0 20px;
      border-top: 1px solid #c4c4c4;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:last-child {
        border-bottom: 1px solid #c4c4c4;
      }
      &.is-active {
        color: $primaryColor;
        font-weight: 700;
        background-color: #fbebe0;
        position: relative;
        &::after {
          content: '';
          display: block;
          position: absolute;
          right: 0;
          bottom: 50%;
          transform: translateY(50%);
          border: 12px solid transparent;
          border-right-color: #fff;
        }
      }
      .scene-icon {
        width: 30px;
        height: 30px;
        display: inline-block;
        margin-right: 10px;
      }
    }
    main {
      flex: 1;
      .situation-tabs-content-main-wrap {
        padding: 50px 80px;
        text-align: left;
        counter-reset: count;
        .situation-tabs-content-main-content {
          position: relative;
          counter-increment: count;
          .bg-text{
            position: absolute;
            color: #f5f5f5;
            top: -80px;
            font-weight: 700;
            font-size: 85px;
          }
          h2 {
            position: relative;
            z-index: 1;
            font-size: 22px;
            color: #333333;
            margin: 20px 40px;
          }
          h4{
            margin: 20px 0;
            line-height: 1.5;
          }
          p{
            line-height: 1.5;
            margin: 20px 23px;
          }
          li{
            line-height: 1.5;
            margin: 20px 0;
            white-space: nowrap;
            .label{
              display: inline-block;
              width: 130px;
            }
          }
          .break-line{
            white-space: normal;
          }
          .icon-squire{
            position: relative;
            padding-left: 23px;
            &::before{
              content: '';
              display: block;
              position: absolute;
              top: 4px;
              left: 0;
              box-sizing: border-box;
              width: 13px;
              height: 13px;
              border: 2px solid $primaryColor;
              margin-right: 10px;
            }
          }
          .icon-circle{
            position: relative;
            padding-left: 23px;
            &::before{
              content: '';
              display: block;
              position: absolute;
              top: 6px;
              left: 0;
              box-sizing: border-box;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background-color: $primaryColor;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
  
}
</style>